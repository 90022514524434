// yes, we're going to be crude and load all the words into memory... it's really not so bad.
// the list comes from this gem:
// http://www.ashley-bovan.co.uk/words/partsofspeech.html

module.exports = ["abased",
"abject",
"able",
"abloom",
"ablush",
"abreast",
"abridged",
"abroach",
"abroad",
"abrupt",
"abscessed",
"absolved",
"absorbed",
"abstruse",
"absurd",
"abused",
"abuzz",
"accrete",
"accrued",
"accurst",
"acerb",
"aching",
"acock",
"acold",
"acorned",
"acred",
"acrid",
"acting",
"added",
"addle",
"addorsed",
"adept",
"adjunct",
"admired",
"adnate",
"adored",
"adrift",
"adroit",
"adscript",
"adult",
"adunc",
"adust",
"advised",
"aery",
"afeard",
"afeared",
"affine",
"affined",
"afire",
"aflame",
"afloat",
"afoot",
"afoul",
"afraid",
"after",
"aftmost",
"agape",
"agaze",
"aged",
"ageing",
"ageless",
"agelong",
"aggrieved",
"aghast",
"agile",
"aging",
"agleam",
"agley",
"aglow",
"agnate",
"ago",
"agog",
"agone",
"agreed",
"aground",
"ahead",
"ahorse",
"ahull",
"aidful",
"aidless",
"ailing",
"aimless",
"ain",
"air",
"airborne",
"airless",
"airsick",
"airtight",
"ajar",
"akin",
"alar",
"alate",
"alert",
"algal",
"algid",
"algoid",
"alien",
"alight",
"alike",
"alined",
"alive",
"alleged",
"allowed",
"alloyed",
"alone",
"aloof",
"alright",
"altered",
"altern",
"alvine",
"amazed",
"amber",
"amiss",
"amok",
"amort",
"ample",
"amuck",
"amused",
"android",
"angled",
"anguine",
"anguished",
"anile",
"announced",
"ansate",
"anti",
"antic",
"antique",
"antlered",
"antlike",
"antrorse",
"anxious",
"apart",
"apeak",
"apish",
"appalled",
"applied",
"appressed",
"arcane",
"arching",
"argent",
"arid",
"armchair",
"armless",
"armored",
"aroid",
"aroused",
"arranged",
"arrant",
"arrased",
"arrhythmic",
"artful",
"artless",
"arty",
"ashake",
"ashamed",
"ashen",
"ashy",
"askance",
"askant",
"askew",
"asking",
"aslant",
"asleep",
"aslope",
"asphalt",
"asprawl",
"asquint",
"assumed",
"assured",
"astir",
"astral",
"astute",
"aswarm",
"athirst",
"atilt",
"atrip",
"attached",
"attack",
"attent",
"attired",
"attrite",
"attuned",
"audile",
"aurous",
"austere",
"averse",
"avid",
"avowed",
"awake",
"aware",
"awash",
"away",
"aweless",
"awesome",
"awestruck",
"awful",
"awheel",
"awing",
"awkward",
"awnless",
"awry",
"axile",
"azure",
"babbling",
"baccate",
"backboned",
"backhand",
"backless",
"backmost",
"backstage",
"backstair",
"backstairs",
"backswept",
"backward",
"backwoods",
"baddish",
"baffling",
"baggy",
"bairnly",
"balanced",
"balding",
"baldish",
"baleful",
"balky",
"bally",
"balmy",
"banal",
"bandaged",
"banded",
"baneful",
"bangled",
"bankrupt",
"banner",
"bannered",
"baptist",
"bar",
"barbate",
"bardic",
"bardy",
"bareback",
"barebacked",
"barefaced",
"barefoot",
"barer",
"barest",
"baric",
"barish",
"barkless",
"barky",
"barmy",
"baroque",
"barrelled",
"baseless",
"baser",
"basest",
"bashful",
"basic",
"bassy",
"bastioned",
"bated",
"battered",
"battled",
"batty",
"bausond",
"bawdy",
"beaded",
"beady",
"beaky",
"beaming",
"beamish",
"beamless",
"beamy",
"beardless",
"bearish",
"bearlike",
"beastlike",
"beastly",
"beaten",
"beating",
"beauish",
"becalmed",
"bedded",
"bedfast",
"bedight",
"bedimmed",
"bedrid",
"beechen",
"beefy",
"beery",
"beetle",
"befogged",
"begrimed",
"beguiled",
"behind",
"bellied",
"belted",
"bemazed",
"bemused",
"bended",
"bending",
"bendwise",
"bendy",
"benign",
"benthic",
"benzal",
"bereft",
"berried",
"berserk",
"besieged",
"bespoke",
"besprent",
"bestead",
"bestial",
"betrothed",
"beveled",
"biased",
"bifid",
"biform",
"bigger",
"biggest",
"biggish",
"bijou",
"bilgy",
"bilious",
"billion",
"billionth",
"bilobed",
"binate",
"biped",
"birchen",
"birdlike",
"birken",
"bistred",
"bitchy",
"bitless",
"bitten",
"bitty",
"bivalve",
"bizarre",
"blackish",
"blameful",
"blameless",
"blaring",
"blasted",
"blasting",
"blatant",
"bleary",
"blended",
"blending",
"blindfold",
"blinding",
"blinking",
"blissful",
"blissless",
"blithesome",
"bloated",
"blockish",
"blocky",
"blooded",
"bloodied",
"bloodshot",
"bloodstained",
"blooming",
"bloomless",
"bloomy",
"blotchy",
"blotto",
"blotty",
"blowhard",
"blowsy",
"blowy",
"blowzy",
"blubber",
"bluer",
"bluest",
"bluish",
"blurry",
"blushful",
"blushless",
"boarish",
"boastful",
"boastless",
"bobtail",
"bodger",
"bodied",
"boding",
"boggy",
"bogus",
"bomb",
"bombproof",
"boneless",
"bonism",
"bonkers",
"bony",
"bonzer",
"bookish",
"bookless",
"boorish",
"booted",
"bootleg",
"bootless",
"boozy",
"bordered",
"boring",
"bosker",
"bosky",
"bosom",
"bosomed",
"bossy",
"botchy",
"bouffant",
"boughten",
"bouilli",
"bouncy",
"bounded",
"bounden",
"boundless",
"bousy",
"bovid",
"bovine",
"bowing",
"boxlike",
"boyish",
"bracing",
"brackish",
"bractless",
"braggart",
"bragging",
"braided",
"brainless",
"brainsick",
"brainy",
"brakeless",
"brambly",
"branching",
"branchless",
"branchlike",
"branny",
"brashy",
"brassy",
"brattish",
"bratty",
"braver",
"bravest",
"braving",
"brawny",
"brazen",
"breaking",
"breakneck",
"breasted",
"breathless",
"breathy",
"breechless",
"breeding",
"breezeless",
"breezy",
"brickle",
"bricky",
"bridgeless",
"briefless",
"brilliant",
"brimful",
"brimless",
"brimming",
"brinded",
"brindle",
"brindled",
"brinish",
"briny",
"bristly",
"brittle",
"broadband",
"broadcast",
"broadish",
"broadloom",
"broadside",
"broch",
"broguish",
"bronzy",
"broody",
"broomy",
"browless",
"brownish",
"browny",
"bruising",
"brumal",
"brumous",
"brunet",
"brunette",
"brushless",
"brushy",
"brutal",
"brute",
"brutelike",
"brutish",
"bubbly",
"buccal",
"buckish",
"buckram",
"buckshee",
"buckskin",
"bucktooth",
"bucktoothed",
"budless",
"buggy",
"bughouse",
"buirdly",
"bulbar",
"bulbous",
"bulgy",
"bulky",
"bullate",
"bullied",
"bullish",
"bumbling",
"bumptious",
"bumpy",
"bunchy",
"bunted",
"buoyant",
"burdened",
"burghal",
"buried",
"burlesque",
"burly",
"burry",
"bursal",
"bursting",
"bushy",
"busied",
"buskined",
"bustled",
"busty",
"buttocked",
"buxom",
"bygone",
"byssal",
"caboched",
"caboshed",
"caddish",
"cadenced",
"cadent",
"cadgy",
"cagey",
"cagy",
"caitiff",
"calcic",
"calfless",
"caller",
"callous",
"callow",
"calmy",
"campy",
"cancelled",
"cancrine",
"cancroid",
"candent",
"candied",
"canine",
"cankered",
"canny",
"canty",
"cany",
"capeskin",
"caprine",
"captious",
"captive",
"cardboard",
"carefree",
"careful",
"careless",
"careworn",
"caring",
"carking",
"carlish",
"carmine",
"carnose",
"carpal",
"carping",
"carsick",
"carven",
"casebook",
"casteless",
"castled",
"catching",
"catchweight",
"catchy",
"cattish",
"catty",
"caudate",
"cauline",
"causal",
"causeless",
"cautious",
"cayenned",
"ceaseless",
"cecal",
"cedarn",
"ceilinged",
"censured",
"centered",
"centred",
"centric",
"centrist",
"centum",
"cercal",
"cerise",
"cerous",
"certain",
"cervid",
"cervine",
"cestoid",
"chaffless",
"chaffy",
"chainless",
"chairborne",
"chaliced",
"chalky",
"chambered",
"chanceful",
"chanceless",
"chancy",
"changeful",
"changeless",
"changing",
"chapeless",
"chargeful",
"chargeless",
"charming",
"charmless",
"charry",
"chartered",
"chartless",
"chary",
"chasmal",
"chasmic",
"chasmy",
"chasseur",
"chaster",
"chastest",
"chastised",
"chatty",
"checkered",
"checky",
"cheeky",
"cheerful",
"cheerless",
"cheerly",
"cheery",
"cheesy",
"chelate",
"chemic",
"chequy",
"cherty",
"chestnut",
"chesty",
"chevroned",
"chewy",
"chichi",
"chiefless",
"chiefly",
"chiffon",
"childing",
"childish",
"childless",
"childlike",
"childly",
"chill",
"chilly",
"chin",
"chintzy",
"chipper",
"chippy",
"chirpy",
"chiseled",
"chiselled",
"chlorous",
"chocker",
"choicer",
"chokey",
"choking",
"choky",
"chondral",
"choosey",
"choosy",
"chopping",
"choppy",
"choral",
"chordal",
"chordate",
"choric",
"chrismal",
"chronic",
"chthonic",
"chubby",
"chuffy",
"chummy",
"chunky",
"churchless",
"churchly",
"churchward",
"churchy",
"churlish",
"churning",
"chymous",
"cichlid",
"cirrate",
"cirrose",
"cirsoid",
"cissoid",
"cissy",
"cisted",
"cistic",
"citrous",
"citrus",
"clamant",
"clammy",
"clankless",
"clannish",
"clasping",
"classless",
"classy",
"clastic",
"clathrate",
"clausal",
"claustral",
"clavate",
"clawless",
"clayey",
"clayish",
"cleanly",
"cleansing",
"clerkish",
"clerkly",
"cliffy",
"clingy",
"clinquant",
"clipping",
"cliquey",
"cliquish",
"cliquy",
"clitic",
"clockwise",
"cloddish",
"cloddy",
"clogging",
"cloggy",
"cloistered",
"cloistral",
"clonic",
"closer",
"closest",
"clotty",
"clouded",
"cloudless",
"cloudy",
"clovered",
"clownish",
"cloying",
"clubby",
"clucky",
"clueless",
"clumpy",
"clumsy",
"clustered",
"coaly",
"coarser",
"coarsest",
"coastal",
"coastward",
"coastwise",
"coated",
"coatless",
"coccal",
"coccoid",
"cockney",
"cocksure",
"cocky",
"coffered",
"cogent",
"cognate",
"coky",
"coldish",
"collapsed",
"collect",
"colloid",
"colly",
"coltish",
"columned",
"comal",
"comate",
"combined",
"combless",
"combust",
"comely",
"comfy",
"coming",
"commie",
"commo",
"comose",
"compact",
"compelled",
"compleat",
"complete",
"compo",
"composed",
"concave",
"conceived",
"concerned",
"conchal",
"conchate",
"concise",
"condemned",
"condign",
"conferred",
"confined",
"confirmed",
"confused",
"conjoined",
"conjoint",
"conjunct",
"connate",
"conoid",
"conscious",
"constrained",
"consumed",
"contained",
"contrate",
"contrite",
"contrived",
"controlled",
"contused",
"convex",
"convict",
"convinced",
"cooing",
"cooking",
"coolish",
"copied",
"coppiced",
"corbelled",
"cordate",
"corded",
"cordial",
"cordless",
"coreless",
"corking",
"corky",
"cormous",
"cornered",
"cornute",
"corny",
"correct",
"corrupt",
"corvine",
"cosher",
"costal",
"costate",
"costive",
"costly",
"costumed",
"cottaged",
"couchant",
"counter",
"countless",
"courant",
"couthie",
"couthy",
"coxal",
"coyish",
"cozy",
"crabbed",
"crabby",
"crablike",
"crabwise",
"crackbrained",
"crackers",
"cracking",
"crackjaw",
"crackle",
"crackling",
"crackly",
"crackpot",
"craftless",
"crafty",
"cragged",
"craggy",
"cranky",
"crannied",
"crashing",
"craven",
"crawling",
"crawly",
"creaky",
"creamlaid",
"creamy",
"creasy",
"credent",
"creedal",
"creepy",
"crenate",
"crescive",
"cressy",
"crestless",
"cricoid",
"crimeless",
"crimpy",
"crimson",
"crinal",
"cringing",
"crinite",
"crinkly",
"crinoid",
"crinose",
"crippling",
"crispate",
"crispy",
"crisscross",
"cristate",
"croaky",
"crookback",
"crooked",
"crosiered",
"crossbred",
"crosstown",
"crosswise",
"croupous",
"croupy",
"crowded",
"crowing",
"crowning",
"crownless",
"crucial",
"cruder",
"crudest",
"cruel",
"crumbly",
"crumby",
"crummy",
"crumpled",
"crunchy",
"crural",
"crushing",
"crustal",
"crusted",
"crustless",
"crusty",
"crying",
"cryptal",
"cryptic",
"ctenoid",
"cubbish",
"cubist",
"cuboid",
"cultic",
"cultish",
"cultrate",
"cumbrous",
"cunning",
"cupric",
"cuprous",
"curbless",
"curdy",
"cureless",
"curly",
"currish",
"cursed",
"cursing",
"cursive",
"curtate",
"curving",
"curvy",
"cushy",
"cuspate",
"cussed",
"custom",
"cutcha",
"cuter",
"cutest",
"cyan",
"cycloid",
"cyclone",
"cymoid",
"cymose",
"cystoid",
"cytoid",
"czarist",
"daedal",
"daffy",
"daimen",
"dainty",
"daisied",
"dam",
"damaged",
"damfool",
"damning",
"dampish",
"dancing",
"dangling",
"dapper",
"dapple",
"dappled",
"daring",
"darkish",
"darkling",
"darksome",
"dashing",
"dastard",
"dated",
"dateless",
"dauby",
"dauntless",
"daylong",
"daytime",
"deathful",
"deathless",
"deathlike",
"deathly",
"deathy",
"debased",
"debauched",
"deceased",
"decent",
"declared",
"decreed",
"decurved",
"dedal",
"deedless",
"defaced",
"defiled",
"defined",
"deflexed",
"deformed",
"defunct",
"deictic",
"deism",
"deject",
"deltoid",
"demure",
"dendroid",
"denser",
"densest",
"dentate",
"dentoid",
"deposed",
"depraved",
"depressed",
"deprived",
"deranged",
"dermal",
"dermic",
"dermoid",
"dernier",
"descant",
"described",
"desert",
"deserved",
"designed",
"desired",
"desmoid",
"despised",
"destined",
"detached",
"detailed",
"deuced",
"deviled",
"devoid",
"devout",
"dewlapped",
"dewy",
"dextral",
"dextrorse",
"dextrous",
"diarch",
"dicey",
"dickey",
"dicky",
"diet",
"diffuse",
"diffused",
"dighted",
"diglot",
"dilute",
"dimmest",
"dimming",
"dimply",
"dingbats",
"dingy",
"dinkies",
"dinky",
"diplex",
"diploid",
"dippy",
"direful",
"direr",
"direst",
"dirty",
"discalced",
"disclosed",
"discoid",
"discreet",
"discrete",
"diseased",
"disgraced",
"disguised",
"dishy",
"disjoined",
"disjoint",
"disjunct",
"disliked",
"dispensed",
"disperse",
"dispersed",
"displayed",
"displeased",
"disposed",
"dissolved",
"distal",
"distent",
"distilled",
"distinct",
"distrait",
"distraught",
"distressed",
"disturbed",
"distyle",
"disused",
"divers",
"diverse",
"divorced",
"dizzied",
"dizzy",
"docile",
"dockside",
"doddered",
"dodgy",
"dogged",
"dogging",
"doggish",
"doggone",
"doggoned",
"doggy",
"doglike",
"doited",
"doleful",
"dolesome",
"dollish",
"doltish",
"donnard",
"donnered",
"donnish",
"donsie",
"dopey",
"dopy",
"dormant",
"dormie",
"dormy",
"dorty",
"dotal",
"doting",
"dotted",
"doty",
"doubling",
"doubtful",
"doubting",
"doubtless",
"doughy",
"dovelike",
"dovetailed",
"dovish",
"dowdy",
"dowie",
"downbeat",
"downhill",
"downrange",
"downright",
"downstage",
"downstair",
"downstairs",
"downstate",
"downstream",
"downwind",
"dozen",
"dozenth",
"dozing",
"dozy",
"draffy",
"drafty",
"dragging",
"draggy",
"draining",
"drastic",
"dratted",
"draughty",
"dreadful",
"dreamful",
"dreamless",
"dreamlike",
"dreamy",
"dreary",
"dreggy",
"dressy",
"drier",
"driest",
"driftless",
"drifty",
"drippy",
"driven",
"drizzly",
"droning",
"dronish",
"droopy",
"dropping",
"dropsied",
"drossy",
"droughty",
"drouthy",
"drowsing",
"drowsy",
"drudging",
"drumly",
"drunken",
"dryer",
"ducal",
"duckbill",
"duckie",
"ducky",
"ductile",
"duddy",
"dudish",
"dulcet",
"dullish",
"dumbstruck",
"dumpish",
"dun",
"dungy",
"dural",
"duskish",
"dusky",
"dustless",
"dustproof",
"dwarfish",
"dyeline",
"dying",
"earnest",
"earthborn",
"earthbound",
"earthen",
"earthly",
"earthquaked",
"earthward",
"earthy",
"easeful",
"eastbound",
"eastmost",
"eastward",
"eaten",
"eating",
"ebon",
"eccrine",
"ecru",
"edgeless",
"edging",
"edgy",
"eely",
"eerie",
"eery",
"effete",
"effluent",
"effuse",
"egal",
"eighteen",
"eighteenth",
"eightfold",
"eighty",
"elapsed",
"elder",
"eldest",
"eldritch",
"elect",
"elfin",
"elfish",
"elite",
"elmy",
"elvish",
"embowed",
"emersed",
"emptied",
"enarched",
"enate",
"encased",
"enceinte",
"endarch",
"endless",
"endmost",
"endorsed",
"endways",
"enforced",
"engorged",
"engrailed",
"engrained",
"engraved",
"enhanced",
"enjambed",
"enlarged",
"enorm",
"enough",
"enow",
"enraged",
"enrapt",
"enrolled",
"enslaved",
"enthralled",
"entire",
"entranced",
"enured",
"enwrapped",
"equine",
"equipped",
"erased",
"erect",
"ermined",
"erose",
"errant",
"errhine",
"erring",
"ersatz",
"erstwhile",
"escaped",
"essive",
"estranged",
"estrous",
"eterne",
"ethic",
"ethmoid",
"ethnic",
"eustyle",
"evens",
"evoked",
"exact",
"exarch",
"exchanged",
"excused",
"exempt",
"exhaled",
"expert",
"expired",
"exposed",
"exsert",
"extant",
"extinct",
"extrorse",
"eyeless",
"fabled",
"faceless",
"facete",
"factious",
"faddish",
"faddy",
"faded",
"fadeless",
"fading",
"faecal",
"failing",
"faintish",
"fairish",
"faithful",
"faithless",
"falcate",
"falser",
"falsest",
"fameless",
"famished",
"famous",
"fancied",
"fanfold",
"fangled",
"fangless",
"farand",
"farci",
"farfetched",
"farming",
"farouche",
"farrow",
"farther",
"farthest",
"fatal",
"fated",
"fateful",
"fatigue",
"fatigued",
"fatless",
"fatter",
"fattest",
"fattish",
"faucal",
"faucial",
"faultless",
"faulty",
"faunal",
"favored",
"favoured",
"fearful",
"fearless",
"fearsome",
"feastful",
"feathered",
"featured",
"febrile",
"fecal",
"feckless",
"fecund",
"federalist",
"feeble",
"feeblish",
"feeling",
"feisty",
"feline",
"felon",
"felsic",
"fenny",
"feodal",
"feral",
"ferine",
"ferny",
"fervent",
"fervid",
"fesswise",
"festal",
"festive",
"fetching",
"fetial",
"fetid",
"feudal",
"fewer",
"fibered",
"fibroid",
"fibrous",
"fickle",
"fictile",
"fictive",
"fiddling",
"fiddly",
"fiendish",
"fiercer",
"fiercest",
"fifteen",
"fifteenth",
"fifty",
"filar",
"filial",
"filose",
"filthy",
"filtrable",
"financed",
"fineable",
"finer",
"finest",
"fingered",
"finished",
"finite",
"finless",
"finny",
"fireproof",
"firry",
"fishy",
"fissile",
"fistic",
"fitchy",
"fitful",
"fitted",
"fitter",
"fitting",
"fivefold",
"fizzy",
"flabby",
"flaccid",
"flagging",
"flaggy",
"flagrant",
"flameproof",
"flaming",
"flamy",
"flappy",
"flaring",
"flashy",
"flatling",
"flattest",
"flattish",
"flaunty",
"flawless",
"flawy",
"flaxen",
"fleckless",
"fledgeling",
"fledgling",
"fledgy",
"fleeceless",
"fleecy",
"fleeing",
"fleeting",
"fleshless",
"fleshly",
"fleshy",
"flexile",
"flightless",
"flighty",
"flimsy",
"flinty",
"flippant",
"flipping",
"flitting",
"floaty",
"floccose",
"floccus",
"flooded",
"floodlit",
"floppy",
"florid",
"flory",
"flossy",
"floury",
"flowered",
"flowing",
"fluent",
"fluffy",
"flukey",
"fluky",
"flurried",
"fluted",
"fluty",
"flyweight",
"foamless",
"foamy",
"focused",
"focussed",
"foetal",
"foetid",
"fogbound",
"foggy",
"fogless",
"folded",
"folkish",
"folklore",
"folksy",
"fontal",
"foodless",
"foolish",
"foolproof",
"footed",
"footless",
"footling",
"footsore",
"footworn",
"foppish",
"forceful",
"forceless",
"forehand",
"foremost",
"forenamed",
"foresaid",
"foreseen",
"forespent",
"foretold",
"forfeit",
"forky",
"former",
"formless",
"fornent",
"forspent",
"forte",
"forthright",
"fortis",
"forworn",
"foughten",
"fourfold",
"fourscore",
"foursquare",
"fourteenth",
"foxy",
"fozy",
"fractious",
"fractured",
"fragile",
"fragrant",
"frantic",
"fratchy",
"fraudful",
"frazzled",
"freakish",
"freaky",
"freckly",
"freebie",
"freeborn",
"freeing",
"freer",
"freest",
"frenzied",
"frequent",
"freshman",
"fretful",
"fretted",
"fretty",
"fribble",
"friended",
"friendless",
"frightened",
"frightful",
"frilly",
"fringeless",
"frisky",
"frizzly",
"frizzy",
"frockless",
"frolic",
"fronded",
"frontal",
"frontier",
"frontless",
"frosted",
"frostless",
"frostlike",
"frosty",
"frothy",
"froward",
"frowsty",
"frowsy",
"frowzy",
"frozen",
"fructed",
"frugal",
"fruited",
"fruitful",
"fruitless",
"fruity",
"frumpish",
"frumpy",
"frustrate",
"fubsy",
"fucoid",
"fugal",
"fulfilled",
"fulgent",
"fulgid",
"fulsome",
"fulvous",
"fumy",
"funded",
"funest",
"fungal",
"fungoid",
"fungous",
"funky",
"furcate",
"furry",
"furthest",
"furtive",
"furzy",
"fuscous",
"fusil",
"fusile",
"fussy",
"fustian",
"fusty",
"futile",
"fuzzy",
"gabbroid",
"gabled",
"gadoid",
"gadrooned",
"gaga",
"gainful",
"gainless",
"gainly",
"gaited",
"galliard",
"galling",
"gallooned",
"galore",
"gamer",
"gamesome",
"gamest",
"gamey",
"gamic",
"gammy",
"gamy",
"gangling",
"gangly",
"ganoid",
"gaping",
"gardant",
"garish",
"garni",
"gassy",
"gated",
"gateless",
"gaudy",
"gaumless",
"gauzy",
"gawky",
"gawsy",
"gearless",
"geegaw",
"gelded",
"gelid",
"gemel",
"gemmate",
"gemmy",
"genal",
"genial",
"genic",
"genteel",
"genty",
"georgic",
"germane",
"gestic",
"gewgaw",
"ghastful",
"ghastly",
"ghostly",
"ghoulish",
"gibbose",
"gibbous",
"giddied",
"giddy",
"gifted",
"giggly",
"gilded",
"gimcrack",
"gimlet",
"gimpy",
"girlish",
"girly",
"giving",
"glabrate",
"glabrous",
"glacial",
"gladsome",
"glaikit",
"glairy",
"glandered",
"glaring",
"glary",
"glasslike",
"glassy",
"gleeful",
"gleesome",
"gleety",
"glenoid",
"glial",
"glibber",
"glibbest",
"globate",
"globoid",
"globose",
"gloomful",
"glooming",
"gloomy",
"glossies",
"glossy",
"glottic",
"glowing",
"gluey",
"glummer",
"glummest",
"glumpy",
"glutted",
"glyphic",
"glyptic",
"gnarly",
"gnathic",
"gneissic",
"gneissoid",
"gnomic",
"gnomish",
"goalless",
"goateed",
"goatish",
"godless",
"godlike",
"godly",
"goitrous",
"goodish",
"goodly",
"gooey",
"goofy",
"goosey",
"goosy",
"gorgeous",
"gormless",
"gorsy",
"gory",
"gouty",
"gowaned",
"goyish",
"graceful",
"graceless",
"gracile",
"gracious",
"gradely",
"grainy",
"grapey",
"grapy",
"grasping",
"graspless",
"grassy",
"grateful",
"grating",
"gratis",
"grave",
"gravel",
"graveless",
"gravest",
"gravid",
"grayish",
"greening",
"greenish",
"greensick",
"greyish",
"griefless",
"grieving",
"grimmer",
"grimmest",
"grimy",
"gripping",
"gripple",
"grippy",
"grisly",
"gristly",
"gritty",
"grizzled",
"groggy",
"groovy",
"groping",
"grotesque",
"grotty",
"grouchy",
"groundless",
"grouty",
"grubby",
"grudging",
"gruesome",
"gruffish",
"grumbly",
"grummer",
"grummest",
"grumose",
"grumous",
"grumpy",
"gruntled",
"guardant",
"guarded",
"guardless",
"guideless",
"guiding",
"guileful",
"guileless",
"guiltless",
"guilty",
"gular",
"gulfy",
"gummous",
"gummy",
"gumptious",
"gunless",
"gushy",
"gusty",
"gutless",
"gutsy",
"gutta",
"guttate",
"gyral",
"gyrate",
"gyrose",
"habile",
"hackly",
"hackneyed",
"hadal",
"haemal",
"haemic",
"haggish",
"hairless",
"hairlike",
"halest",
"halftone",
"hallowed",
"haloid",
"halting",
"hamate",
"hammered",
"hammy",
"handed",
"handled",
"handless",
"handmade",
"handsome",
"handworked",
"handwrought",
"handy",
"hangdog",
"hapless",
"haploid",
"haptic",
"harassed",
"hardback",
"hardened",
"hardwood",
"harlot",
"harmful",
"harmless",
"harnessed",
"harried",
"hastate",
"hasty",
"hatching",
"hated",
"hateful",
"hatless",
"hatted",
"haughty",
"haunted",
"haunting",
"hawkish",
"hawklike",
"haywire",
"hazy",
"headed",
"headfirst",
"headless",
"headlong",
"headmost",
"headstrong",
"heady",
"healing",
"healthful",
"healthy",
"heaping",
"heapy",
"hearted",
"heartfelt",
"hearties",
"heartless",
"heartsome",
"hearty",
"heated",
"heathen",
"heathy",
"heating",
"heavies",
"heaving",
"hectic",
"hedgy",
"heedful",
"heedless",
"heelless",
"hefty",
"heinous",
"heirless",
"helmless",
"helpful",
"helpless",
"hemal",
"hempen",
"hempy",
"hennaed",
"herbaged",
"herbal",
"herbless",
"herby",
"here",
"hidden",
"highbrow",
"highest",
"hilding",
"hilly",
"hinder",
"hindmost",
"hindward",
"hipper",
"hippest",
"hippy",
"hircine",
"hirsute",
"hispid",
"hissing",
"histie",
"histoid",
"hitchy",
"hither",
"hiveless",
"hivelike",
"hobnail",
"hobnailed",
"hoggish",
"hoiden",
"holey",
"hollow",
"holmic",
"holstered",
"homebound",
"homeless",
"homelike",
"homely",
"homesick",
"homespun",
"homeward",
"homey",
"homy",
"honest",
"honeyed",
"honied",
"hoodless",
"hoofless",
"hooly",
"hopeful",
"hopeless",
"hopping",
"horal",
"hornish",
"hornless",
"hornlike",
"horny",
"horrent",
"horrid",
"horsey",
"horsy",
"hotfoot",
"hotshot",
"hotter",
"hottest",
"hotting",
"hottish",
"hourlong",
"hourly",
"housebound",
"houseless",
"hoven",
"howling",
"hoyden",
"hueless",
"huffish",
"huffy",
"huger",
"hugest",
"hulking",
"hulky",
"humbler",
"humdrum",
"humic",
"humid",
"hummel",
"humpbacked",
"humpy",
"hunchback",
"hunchbacked",
"hundredth",
"hungry",
"hunky",
"hunted",
"hurling",
"hurried",
"hurtful",
"hurtless",
"hurtling",
"husky",
"hydric",
"hydro",
"hydroid",
"hydrous",
"hymnal",
"hyoid",
"hyphal",
"hypnoid",
"icky",
"ictic",
"idem",
"idled",
"idlest",
"idling",
"iffy",
"ignored",
"imbued",
"immane",
"immense",
"immersed",
"immune",
"impel",
"impelled",
"impish",
"implied",
"imposed",
"improved",
"impure",
"inane",
"inapt",
"inboard",
"inborn",
"inbound",
"inbred",
"inbreed",
"inby",
"incased",
"incensed",
"incised",
"incog",
"increased",
"incrust",
"incult",
"incurved",
"incuse",
"indign",
"indoor",
"indrawn",
"inept",
"infect",
"infelt",
"infirm",
"inflamed",
"inflexed",
"inform",
"informed",
"ingrain",
"ingrained",
"ingrate",
"ingrown",
"inhaled",
"inhumed",
"injured",
"inky",
"inlaid",
"inmost",
"innate",
"inphase",
"inrush",
"insane",
"inscribed",
"inshore",
"insides",
"inspired",
"instinct",
"insured",
"intact",
"intense",
"intent",
"intern",
"interred",
"intime",
"intoed",
"intoned",
"intown",
"introrse",
"inured",
"involved",
"inward",
"inwrought",
"irate",
"ireful",
"irksome",
"itching",
"itchy",
"ivied",
"jaded",
"jadish",
"jagged",
"jaggy",
"jammy",
"jangly",
"jannock",
"japan",
"jarring",
"jasp",
"jaundiced",
"jazzy",
"jealous",
"jejune",
"jellied",
"jerky",
"jessant",
"jestful",
"jesting",
"jet",
"jetting",
"jetty",
"jeweled",
"jewelled",
"jiggered",
"jiggish",
"jiggly",
"jingly",
"jobless",
"jocose",
"jocund",
"jointed",
"jointless",
"jointured",
"joking",
"jolty",
"jouncing",
"jowly",
"joyful",
"joyless",
"joyous",
"jubate",
"jugal",
"jugate",
"juiceless",
"juicy",
"jumbled",
"jumpy",
"jungly",
"jural",
"jurant",
"jussive",
"jutting",
"kacha",
"kaput",
"karmic",
"karstic",
"kayoed",
"kerchiefed",
"keyless",
"khaki",
"kidnapped",
"killing",
"kilted",
"kindless",
"kindly",
"kindred",
"kingless",
"kinglike",
"kingly",
"kinky",
"kinless",
"kirtled",
"kittle",
"klephtic",
"klutzy",
"knaggy",
"knavish",
"kneeling",
"knickered",
"knifeless",
"knightless",
"knightly",
"knitted",
"knobby",
"knotless",
"knotted",
"knotty",
"knowing",
"knuckly",
"knurly",
"kookie",
"kooky",
"kosher",
"kutcha",
"labelled",
"labile",
"labored",
"laboured",
"labrid",
"labroid",
"lacking",
"lacy",
"laddish",
"laden",
"laggard",
"laic",
"lairy",
"laky",
"lambdoid",
"lambent",
"lamblike",
"lamer",
"lamest",
"laming",
"lanate",
"landed",
"landless",
"landscaped",
"landward",
"languid",
"lanky",
"lanose",
"lapelled",
"lapstrake",
"larboard",
"larger",
"largest",
"largish",
"larine",
"larkish",
"larky",
"larval",
"lashing",
"lasting",
"lated",
"lateen",
"later",
"latest",
"lathlike",
"lathy",
"latish",
"latter",
"latticed",
"laurelled",
"lavish",
"lawful",
"lawless",
"lawny",
"leachy",
"leaden",
"leadless",
"leady",
"leafless",
"leafy",
"leaky",
"leaning",
"leaping",
"learned",
"leary",
"leathern",
"ledgy",
"leery",
"leftward",
"legged",
"leggy",
"legit",
"legless",
"leisure",
"leisured",
"lengthways",
"lengthwise",
"lengthy",
"lenis",
"lenten",
"lentic",
"lento",
"lentoid",
"leprose",
"leprous",
"lettered",
"licenced",
"licensed",
"licit",
"lidded",
"lidless",
"liege",
"lifeful",
"lifeless",
"lifelike",
"lifelong",
"lighted",
"lightfast",
"lightful",
"lightish",
"lightless",
"lightsome",
"lightweight",
"lignite",
"likely",
"lilied",
"limbate",
"limbless",
"limey",
"limpid",
"limy",
"liney",
"lingual",
"linty",
"liny",
"lipless",
"lipoid",
"lippy",
"lissom",
"lissome",
"listless",
"lither",
"lithesome",
"lithest",
"lithic",
"litho",
"lithoid",
"litten",
"littler",
"littlest",
"livelong",
"lively",
"livid",
"loaded",
"loamy",
"loathful",
"loathly",
"loathsome",
"lobar",
"lobate",
"lobose",
"lofty",
"logy",
"lonesome",
"longer",
"longhand",
"longing",
"longish",
"longsome",
"longwall",
"longwise",
"looking",
"loonies",
"loopy",
"looser",
"loosest",
"lordless",
"lordly",
"losel",
"losing",
"lossy",
"lotic",
"loudish",
"lounging",
"louring",
"loury",
"lousy",
"loutish",
"louvered",
"louvred",
"loveless",
"lovelorn",
"lovely",
"lovesick",
"lovesome",
"lowly",
"loyal",
"lozenged",
"lubric",
"lucent",
"lucid",
"luckless",
"lukewarm",
"lumpen",
"lumpish",
"lunate",
"lupine",
"lurdan",
"lurid",
"luscious",
"lushy",
"lustful",
"lustral",
"lustred",
"lustrous",
"lusty",
"lying",
"lymphoid",
"lyrate",
"lyric",
"macled",
"madcap",
"maddest",
"madding",
"maigre",
"mainstream",
"maintained",
"makeless",
"makeshift",
"malar",
"male",
"malign",
"malty",
"mammoth",
"man",
"maneless",
"manful",
"mangey",
"mangy",
"manic",
"manky",
"manlike",
"mannered",
"mannish",
"mansard",
"mantic",
"many",
"marching",
"mardy",
"marish",
"maroon",
"married",
"marshy",
"masking",
"massive",
"massy",
"mastless",
"mastoid",
"matchless",
"mated",
"matey",
"matin",
"matted",
"mature",
"maudlin",
"maungy",
"mawkish",
"maxi",
"mazy",
"meager",
"meagre",
"meaning",
"measled",
"measly",
"measured",
"meaty",
"medley",
"melic",
"mellow",
"mensal",
"menseful",
"menseless",
"mere",
"merest",
"merging",
"mesarch",
"meshed",
"mesic",
"messier",
"messy",
"metalled",
"mettled",
"mickle",
"middling",
"midget",
"midi",
"midmost",
"midship",
"midships",
"miffy",
"mighty",
"migrant",
"milkless",
"million",
"millionth",
"millrun",
"mimic",
"mincing",
"minded",
"mindful",
"mindless",
"mingy",
"mini",
"minim",
"minion",
"minute",
"mirky",
"mirthful",
"mirthless",
"miry",
"mis",
"misformed",
"mislaid",
"misproud",
"missive",
"misty",
"mistyped",
"misused",
"mitered",
"mizzen",
"mnemic",
"moanful",
"mobbish",
"model",
"modeled",
"modest",
"modish",
"molal",
"molar",
"moldy",
"molten",
"monarch",
"moneyed",
"monger",
"mongrel",
"monied",
"monism",
"monkish",
"mono",
"monstrous",
"montane",
"monthly",
"mony",
"moody",
"moonish",
"moonless",
"moonlit",
"moonstruck",
"moony",
"moory",
"mopey",
"mopy",
"mordant",
"moreish",
"morish",
"morose",
"mossy",
"motey",
"mothy",
"motile",
"motored",
"mottled",
"mounted",
"mournful",
"mousey",
"mousy",
"mouthless",
"mouthy",
"moveless",
"mowburnt",
"mucid",
"mucking",
"muckle",
"mucky",
"mucoid",
"muddy",
"muggy",
"muley",
"mulish",
"mulley",
"mumchance",
"mundane",
"mural",
"murine",
"murky",
"murrey",
"muscid",
"muscly",
"museful",
"mushy",
"musing",
"musky",
"mussy",
"mustached",
"musty",
"mutant",
"muted",
"muzzy",
"mythic",
"nacred",
"nagging",
"naggy",
"naiant",
"naif",
"nailless",
"naissant",
"naive",
"nameless",
"naming",
"napless",
"napping",
"nappy",
"nary",
"nascent",
"nasty",
"natant",
"natty",
"naughty",
"nauseous",
"needful",
"needless",
"needy",
"negroid",
"neighbor",
"neighbour",
"nephric",
"nerval",
"nervate",
"nerveless",
"nervine",
"nervy",
"nescient",
"nested",
"nestlike",
"netted",
"nettly",
"neural",
"neuron",
"neuter",
"newborn",
"newish",
"newsless",
"newsy",
"nicer",
"nicest",
"nifty",
"niggard",
"niggling",
"nightless",
"nightlong",
"nightly",
"nimble",
"nimbused",
"ninefold",
"nineteen",
"ninety",
"nipping",
"nippy",
"nitid",
"nitty",
"nival",
"nobby",
"nocent",
"nodal",
"nodding",
"nodose",
"nodous",
"noiseless",
"noisette",
"noisome",
"noisy",
"nonplused",
"nonplussed",
"nonstick",
"northmost",
"northward",
"nosey",
"notal",
"notchy",
"noted",
"noteless",
"noticed",
"notour",
"novel",
"novice",
"noxious",
"nubbly",
"nubile",
"nudist",
"numbing",
"nuptial",
"nutant",
"nutlike",
"nutmegged",
"nutty",
"nymphal",
"oafish",
"oaken",
"oarless",
"oaten",
"obese",
"oblate",
"obliged",
"oblique",
"oblong",
"obscene",
"obscure",
"observed",
"obtect",
"obtuse",
"obverse",
"occult",
"ocher",
"ochre",
"ocker",
"oddball",
"offbeat",
"offhand",
"offish",
"offscreen",
"offshore",
"offside",
"often",
"oily",
"okay",
"olden",
"older",
"oldest",
"olid",
"only",
"onshore",
"onside",
"onstage",
"onward",
"oozing",
"oozy",
"ornate",
"orphan",
"ortho",
"oscine",
"osiered",
"osmic",
"osmous",
"otic",
"outback",
"outboard",
"outbound",
"outbred",
"outcast",
"outcaste",
"outdone",
"outdoor",
"outland",
"outlaw",
"outlined",
"outmost",
"outraged",
"outright",
"outsize",
"outsized",
"outspread",
"outworn",
"ovate",
"over",
"overt",
"ovine",
"ovoid",
"owing",
"owlish",
"owllike",
"packaged",
"padded",
"pagan",
"painful",
"painless",
"paler",
"palest",
"paling",
"palish",
"pallid",
"pally",
"palmar",
"palmate",
"palmy",
"palpate",
"palsied",
"paltry",
"paly",
"pan",
"paneled",
"panniered",
"panzer",
"papist",
"pappose",
"pappy",
"par",
"pardine",
"parklike",
"parky",
"parlous",
"parol",
"parotid",
"parted",
"partite",
"pass",
"passant",
"passless",
"pasteboard",
"pasted",
"pastel",
"pasties",
"pasty",
"patchy",
"patent",
"pathic",
"pathless",
"patient",
"paunchy",
"pausal",
"pauseful",
"pauseless",
"pavid",
"pawky",
"payoff",
"peaceful",
"peaceless",
"peachy",
"peaked",
"peaky",
"pearlized",
"peaty",
"pebbly",
"peccant",
"peckish",
"pedal",
"pedate",
"peddling",
"peeling",
"peerless",
"peevish",
"peewee",
"peltate",
"pelting",
"pencilled",
"pendant",
"pendent",
"pending",
"penile",
"pennate",
"pennied",
"pennoned",
"pensile",
"pensive",
"peppy",
"perceived",
"percent",
"percoid",
"perished",
"perjured",
"perky",
"perplexed",
"perverse",
"pesky",
"petalled",
"petite",
"petrous",
"pettish",
"pewter",
"phaseless",
"phasic",
"phasmid",
"phatic",
"phlegmy",
"phocine",
"phonal",
"phoney",
"phonic",
"phony",
"photic",
"phrenic",
"phthisic",
"phylloid",
"physic",
"piano",
"picked",
"pickled",
"picky",
"pictured",
"piddling",
"piebald",
"piecemeal",
"piercing",
"piggie",
"piggish",
"pillaged",
"pillared",
"pilose",
"pimpled",
"pimply",
"pinchbeck",
"piney",
"pinguid",
"pinkish",
"pinnate",
"pinpoint",
"piny",
"pious",
"pipeless",
"pipelike",
"piping",
"pipy",
"piquant",
"piscine",
"pitchy",
"pithy",
"pitted",
"placeless",
"placid",
"placoid",
"plagal",
"plaguey",
"plaguy",
"plaided",
"plaintive",
"plangent",
"plantar",
"plantless",
"plashy",
"plastered",
"plastics",
"plated",
"platy",
"plausive",
"playful",
"pleading",
"pleasing",
"plebby",
"pleural",
"pliant",
"plical",
"plicate",
"plodding",
"plosive",
"plotful",
"plotless",
"plucky",
"plumaged",
"plumate",
"plumbic",
"plumbless",
"plumbous",
"plummy",
"plumose",
"plumy",
"plusher",
"plushest",
"poachy",
"pockmarked",
"pocky",
"podgy",
"poignant",
"pointing",
"pointless",
"pokey",
"pokies",
"poky",
"polished",
"polite",
"pollened",
"poltroon",
"pompous",
"ponceau",
"pongid",
"poorly",
"poppied",
"porcine",
"porky",
"porous",
"porrect",
"portly",
"possessed",
"postern",
"postiche",
"postponed",
"potent",
"potted",
"potty",
"powered",
"practic",
"practiced",
"practised",
"praising",
"prayerful",
"prayerless",
"preachy",
"preborn",
"precast",
"precise",
"prefab",
"preggers",
"pregnant",
"premed",
"premier",
"premiere",
"premorse",
"prepared",
"prepense",
"preschool",
"prescribed",
"prescript",
"present",
"preserved",
"preset",
"pressing",
"pressor",
"presto",
"presumed",
"pretend",
"pretty",
"prewar",
"priceless",
"pricey",
"pricy",
"prideful",
"prideless",
"priestly",
"priggish",
"primal",
"primate",
"primsie",
"princely",
"printed",
"printless",
"prissy",
"pristine",
"privies",
"probing",
"produced",
"profane",
"profaned",
"professed",
"profound",
"profuse",
"prolate",
"prolix",
"pronounced",
"proposed",
"proscribed",
"prostate",
"prostrate",
"prostyle",
"prosy",
"proven",
"provoked",
"prowessed",
"proxy",
"prudent",
"prudish",
"prunted",
"prying",
"pseudo",
"psycho",
"pubic",
"pucka",
"puddly",
"pudgy",
"puffy",
"puggish",
"puggy",
"puisne",
"pukka",
"puling",
"pulpy",
"pulsing",
"punchy",
"punctate",
"punctured",
"pungent",
"punkah",
"puny",
"pupal",
"purblind",
"purer",
"purest",
"purging",
"purplish",
"purpure",
"pursued",
"pursy",
"pushing",
"pushy",
"pussy",
"putrid",
"pygmoid",
"pyknic",
"pyoid",
"quadrate",
"quadric",
"quaggy",
"quaky",
"qualmish",
"quantal",
"quartan",
"quartered",
"quartic",
"quartile",
"queasy",
"queenless",
"queenly",
"quenchless",
"quibbling",
"quickset",
"quiet",
"quilted",
"quinate",
"quinoid",
"quinsied",
"quintan",
"quintic",
"quippish",
"quirky",
"quondam",
"rabic",
"rabid",
"racemed",
"racing",
"racist",
"racy",
"raddled",
"raffish",
"raging",
"rainier",
"rainless",
"rainproof",
"raising",
"rakehell",
"rakish",
"ralline",
"ramal",
"rambling",
"rammish",
"ramose",
"rampant",
"ramstam",
"rancid",
"randie",
"randy",
"rangy",
"ranking",
"raploch",
"rarer",
"rarest",
"raring",
"rascal",
"rasping",
"raspy",
"ratite",
"ratlike",
"rattish",
"rattling",
"rattly",
"ratty",
"raucous",
"raunchy",
"ravaged",
"raving",
"rawboned",
"rawish",
"rayless",
"rearmost",
"rearward",
"reasoned",
"rebel",
"reborn",
"rebuked",
"reckless",
"recluse",
"record",
"rectal",
"recurved",
"redder",
"reddest",
"reddish",
"reedy",
"reeky",
"refer",
"refined",
"regal",
"regent",
"regnal",
"regnant",
"released",
"relieved",
"remiss",
"remnant",
"removed",
"rending",
"renowned",
"rental",
"repand",
"repent",
"replete",
"reproved",
"reptant",
"reptile",
"required",
"rescued",
"resigned",
"resolved",
"restful",
"resting",
"restive",
"restless",
"restored",
"retail",
"retained",
"retired",
"retral",
"retrorse",
"retuse",
"revealed",
"revered",
"reviled",
"revived",
"revolved",
"rheumy",
"rhinal",
"rhodic",
"rhomboid",
"rhotic",
"rhythmic",
"riant",
"ribald",
"ribless",
"riblike",
"ridden",
"rident",
"ridgy",
"riftless",
"righteous",
"rightful",
"rightish",
"rightist",
"rightward",
"rigid",
"riming",
"rimless",
"rimose",
"rimy",
"rindless",
"rindy",
"ringent",
"ringless",
"ripping",
"ripply",
"risen",
"risky",
"riteless",
"ritzy",
"rival",
"riven",
"roadless",
"roasting",
"robust",
"rodded",
"rodless",
"rodlike",
"roguish",
"roily",
"rollneck",
"rompish",
"roofless",
"rooky",
"roomy",
"rooted",
"rootless",
"rootlike",
"ropy",
"roseless",
"roselike",
"rostral",
"rosy",
"rotate",
"rotted",
"rotting",
"rotund",
"roughcast",
"roughish",
"rounded",
"rounding",
"roundish",
"roupy",
"rousing",
"routed",
"routine",
"rowdy",
"rubbly",
"rubied",
"rubric",
"rudish",
"rueful",
"ruffled",
"rufous",
"rugged",
"rugose",
"ruling",
"rumbly",
"rummy",
"rumpless",
"runic",
"runny",
"runtish",
"runty",
"rushing",
"rushy",
"russet",
"rustic",
"rustred",
"rusty",
"ruthful",
"ruthless",
"rutted",
"ruttish",
"rutty",
"saclike",
"sacral",
"sadist",
"sagging",
"said",
"sainted",
"saintly",
"saline",
"sallow",
"saltant",
"salted",
"saltier",
"saltish",
"saltless",
"salty",
"salving",
"sandalled",
"sanded",
"sandy",
"saner",
"sanest",
"sanguine",
"sapid",
"sapless",
"sappy",
"sarcoid",
"sarcous",
"sarky",
"sassy",
"sated",
"satem",
"saucy",
"saut",
"saving",
"savvy",
"scabby",
"scabrous",
"scaldic",
"scalelike",
"scalene",
"scalpless",
"scampish",
"scandent",
"scanty",
"scaphoid",
"scarcer",
"scarcest",
"scarless",
"scary",
"scatheless",
"scathing",
"scatty",
"scentless",
"sceptral",
"scheming",
"schistose",
"schizo",
"schizoid",
"schmaltzy",
"schmalzy",
"scientific",
"scincoid",
"scirrhoid",
"scirrhous",
"scissile",
"scleroid",
"sclerosed",
"sclerous",
"scombrid",
"scombroid",
"scopate",
"scornful",
"scraggly",
"scraggy",
"scrambled",
"scrannel",
"scrappy",
"scratchless",
"scratchy",
"scrawly",
"scrawny",
"screaky",
"screeching",
"screwy",
"scribal",
"scrimpy",
"scroddled",
"scroggy",
"scrotal",
"scrubbed",
"scrubby",
"scruffy",
"scrumptious",
"sculptured",
"scummy",
"scungy",
"scurrile",
"scurry",
"scurvy",
"scutate",
"seaboard",
"seaborne",
"seamless",
"seamy",
"searching",
"seasick",
"seatless",
"seaward",
"second",
"sectile",
"secund",
"secure",
"sedate",
"sedgy",
"seduced",
"seedless",
"seedy",
"seeing",
"seeking",
"seely",
"seeming",
"seemly",
"seismal",
"seismic",
"sejant",
"select",
"selfish",
"selfless",
"selfsame",
"semi",
"senile",
"sensate",
"senseless",
"septal",
"septate",
"sequent",
"sequined",
"seral",
"serene",
"serfish",
"serflike",
"serrate",
"serried",
"serviced",
"servo",
"setose",
"severe",
"sexism",
"sexist",
"sexless",
"sextan",
"sexy",
"shabby",
"shaded",
"shadeless",
"shadowed",
"shady",
"shaftless",
"shaken",
"shaky",
"shallow",
"shalwar",
"shamefaced",
"shameful",
"shameless",
"shapeless",
"shapely",
"shaping",
"shaven",
"shawlless",
"sheathy",
"sheepish",
"shellproof",
"shelly",
"shickered",
"shieldless",
"shieldlike",
"shier",
"shiest",
"shiftless",
"shifty",
"shingly",
"shining",
"shiny",
"shipboard",
"shipless",
"shipshape",
"shirtless",
"shirty",
"shocking",
"shoddy",
"shoeless",
"shopworn",
"shoreless",
"shoreward",
"shortcut",
"shortish",
"shorty",
"shotten",
"showy",
"shredded",
"shredless",
"shrewish",
"shrieval",
"shrinelike",
"shrouding",
"shroudless",
"shrubby",
"shrunken",
"shyer",
"shyest",
"sicker",
"sicklied",
"sickly",
"sideling",
"sidelong",
"sideward",
"sideways",
"sighful",
"sighted",
"sightless",
"sightly",
"sigmate",
"silenced",
"silken",
"silty",
"silvan",
"silvern",
"simplex",
"sincere",
"sinful",
"singing",
"singsong",
"sinless",
"sinning",
"sissy",
"sister",
"sixfold",
"sixteen",
"sixty",
"sizy",
"skaldic",
"sketchy",
"skewbald",
"skidproof",
"skilful",
"skillful",
"skimpy",
"skinking",
"skinless",
"skinny",
"skirtless",
"skittish",
"skyward",
"slaggy",
"slakeless",
"slangy",
"slantwise",
"slapstick",
"slashing",
"slaty",
"slavish",
"sleazy",
"sleekit",
"sleeky",
"sleepless",
"sleepwalk",
"sleepy",
"sleety",
"sleeveless",
"slender",
"slickered",
"slier",
"sliest",
"slighting",
"slimline",
"slimmer",
"slimmest",
"slimming",
"slimsy",
"slimy",
"slinky",
"slippy",
"slipshod",
"sloping",
"sloshy",
"slothful",
"slouchy",
"sloughy",
"sludgy",
"sluggard",
"sluggish",
"sluicing",
"slumbrous",
"slummy",
"slushy",
"sluttish",
"smacking",
"smallish",
"smarmy",
"smartish",
"smarty",
"smashing",
"smeary",
"smectic",
"smelly",
"smileless",
"smiling",
"smitten",
"smokeproof",
"smoking",
"smothered",
"smugger",
"smuggest",
"smutty",
"snafu",
"snaggy",
"snakelike",
"snaky",
"snappish",
"snappy",
"snarly",
"snatchy",
"snazzy",
"sneaking",
"sneaky",
"snider",
"snidest",
"sniffy",
"snippy",
"snobbish",
"snoopy",
"snooty",
"snoozy",
"snoring",
"snotty",
"snouted",
"snowless",
"snowlike",
"snubby",
"snuffly",
"snuffy",
"snugger",
"snuggest",
"snugging",
"soapless",
"soapy",
"soaring",
"sober",
"socko",
"sodden",
"softish",
"softwood",
"soggy",
"sola",
"solemn",
"soli",
"sollar",
"solus",
"solute",
"solvent",
"somber",
"sombre",
"sombrous",
"sometime",
"sonant",
"songful",
"songless",
"sonless",
"sonsie",
"sonsy",
"soothfast",
"soothing",
"sopping",
"soppy",
"sordid",
"sorer",
"sorest",
"sorry",
"sotted",
"sottish",
"soulful",
"soulless",
"soundless",
"soundproof",
"soupy",
"sourish",
"southmost",
"southpaw",
"southward",
"sovran",
"sozzled",
"spaceless",
"spacial",
"spacious",
"spadelike",
"spangly",
"spanking",
"sparid",
"sparing",
"sparkless",
"sparkling",
"sparoid",
"sparry",
"sparser",
"sparsest",
"spastic",
"spathic",
"spathose",
"spatial",
"spavined",
"specious",
"speckled",
"speckless",
"speechless",
"speedful",
"speeding",
"speedless",
"speedy",
"spellbound",
"spendthrift",
"spermic",
"spermous",
"sphagnous",
"sphenic",
"spheral",
"sphereless",
"spherelike",
"spheric",
"sphery",
"sphygmic",
"sphygmoid",
"spicate",
"spicy",
"spiffing",
"spiffy",
"spiky",
"spindling",
"spindly",
"spineless",
"spinose",
"spinous",
"spiral",
"spirant",
"spireless",
"spiroid",
"spiry",
"spiteful",
"splanchnic",
"splashy",
"spleenful",
"spleenish",
"spleeny",
"splendent",
"splendid",
"splendrous",
"splenic",
"splitting",
"splurgy",
"spoken",
"spokewise",
"spongy",
"spooky",
"spoony",
"sportful",
"sportive",
"sportless",
"sporty",
"spotless",
"spotty",
"spousal",
"spouseless",
"spouted",
"spoutless",
"spriggy",
"sprightful",
"sprightly",
"springing",
"springless",
"springlike",
"springtime",
"springy",
"sprucer",
"sprucest",
"sprucing",
"spryer",
"spryest",
"spunky",
"spurless",
"squabby",
"squalid",
"squally",
"squamate",
"squamous",
"squarish",
"squarrose",
"squashy",
"squeaky",
"squeamish",
"squiffy",
"squiggly",
"squirmy",
"squirting",
"squishy",
"stabbing",
"stabile",
"stagey",
"stagnant",
"stagy",
"stalkless",
"stalky",
"stalwart",
"stalworth",
"stannous",
"staple",
"starboard",
"starchy",
"staring",
"starless",
"starlight",
"starlike",
"starring",
"starry",
"starveling",
"starving",
"statant",
"stated",
"stateless",
"stateside",
"statewide",
"statist",
"stative",
"statued",
"steadfast",
"stealthy",
"steamtight",
"steamy",
"stedfast",
"steepled",
"stelar",
"stellar",
"stellate",
"stemless",
"stenosed",
"stepwise",
"steric",
"sterile",
"sternal",
"sternmost",
"sthenic",
"stickit",
"stiffish",
"stifling",
"stilly",
"stilted",
"stingless",
"stingy",
"stinko",
"stintless",
"stirless",
"stirring",
"stockinged",
"stockish",
"stockless",
"stocky",
"stodgy",
"stolen",
"stolid",
"stoneground",
"stoneless",
"stoneware",
"stonkered",
"stopless",
"stopping",
"store",
"storeyed",
"storied",
"stormbound",
"stormless",
"stormproof",
"stotious",
"stoutish",
"straining",
"strangest",
"strapless",
"strapping",
"stratous",
"strawless",
"strawlike",
"streaky",
"streaming",
"streamless",
"streamlined",
"streamy",
"stressful",
"stretchy",
"striate",
"stricken",
"strident",
"strifeful",
"strifeless",
"strigose",
"stringent",
"stringless",
"stringy",
"stripeless",
"stripy",
"strobic",
"strongish",
"strophic",
"stroppy",
"structured",
"strutting",
"strychnic",
"stubbled",
"stubbly",
"stubborn",
"stubby",
"studied",
"stuffy",
"stumbling",
"stumpy",
"stunning",
"stupid",
"sturdied",
"sturdy",
"stutter",
"stylar",
"styleless",
"stylised",
"stylish",
"stylized",
"styloid",
"subdued",
"subfusc",
"subgrade",
"sublimed",
"submerged",
"submersed",
"submiss",
"subscribed",
"subscript",
"subtile",
"subtle",
"succinct",
"suchlike",
"suffused",
"sugared",
"suited",
"sulcate",
"sulfa",
"sulkies",
"sulky",
"sullen",
"sullied",
"sultry",
"sunbaked",
"sunbeamed",
"sunburnt",
"sunfast",
"sunken",
"sunless",
"sunlike",
"sunlit",
"sunproof",
"sunrise",
"sunset",
"sunward",
"super",
"superb",
"supine",
"supple",
"supposed",
"sural",
"surbased",
"surer",
"surest",
"surfy",
"surgeless",
"surging",
"surgy",
"surly",
"surpliced",
"surplus",
"surprised",
"suspect",
"svelter",
"sveltest",
"swainish",
"swampy",
"swanky",
"swaraj",
"swarthy",
"sweated",
"sweaty",
"sweeping",
"sweetmeal",
"swelling",
"sweptwing",
"swindled",
"swingeing",
"swinish",
"swirly",
"swishy",
"swordless",
"swordlike",
"sylphic",
"sylphid",
"sylphish",
"sylphy",
"sylvan",
"systemless",
"taboo",
"tabu",
"tacit",
"tacky",
"tactful",
"tactile",
"tactless",
"tailing",
"tailless",
"taillike",
"tailored",
"taintless",
"taken",
"taking",
"talcose",
"talking",
"talky",
"taloned",
"tameless",
"tamer",
"tamest",
"taming",
"tandem",
"tangential",
"tangier",
"tangled",
"tangy",
"tannic",
"tapeless",
"tapelike",
"tardy",
"tarmac",
"tarnal",
"tarot",
"tarry",
"tarsal",
"tartish",
"tasseled",
"tasselled",
"tasteful",
"tasteless",
"tasty",
"tattered",
"tatty",
"taurine",
"tawdry",
"tawie",
"tearful",
"tearing",
"tearless",
"teary",
"teasing",
"techy",
"teeming",
"teenage",
"teensy",
"teeny",
"telic",
"telling",
"telltale",
"tempered",
"templed",
"tempting",
"tender",
"tenfold",
"tenor",
"tenseless",
"tenser",
"tensest",
"tensing",
"tensive",
"tented",
"tentie",
"tentless",
"tenty",
"tepid",
"terbic",
"terete",
"tergal",
"termless",
"ternate",
"terrene",
"tertial",
"tertian",
"testate",
"testy",
"tetchy",
"textbook",
"textile",
"textless",
"textured",
"thallic",
"thalloid",
"thallous",
"thankful",
"thankless",
"thatchless",
"thecal",
"thecate",
"theism",
"theist",
"themeless",
"thenar",
"thermic",
"theroid",
"thetic",
"thickset",
"thievish",
"thinking",
"thinnish",
"thirdstream",
"thirstless",
"thirsty",
"thirteen",
"thistly",
"thornless",
"thorny",
"thoughtful",
"thoughtless",
"thousandth",
"thowless",
"thrashing",
"threadbare",
"threadlike",
"thready",
"threatful",
"threefold",
"threescore",
"thriftless",
"thrifty",
"thrilling",
"throaty",
"throbbing",
"throbless",
"throneless",
"throwback",
"thudding",
"thuggish",
"thumbless",
"thumblike",
"thumping",
"thymic",
"thymy",
"thyrsoid",
"ticklish",
"tiddly",
"tideless",
"tidied",
"tightknit",
"timbered",
"timeless",
"timely",
"timeous",
"timid",
"tingly",
"tinhorn",
"tinkling",
"tinkly",
"tinny",
"tinsel",
"tintless",
"tiny",
"tippy",
"tiptoe",
"tiptop",
"tireless",
"tiresome",
"titled",
"toeless",
"toey",
"togaed",
"togate",
"toilful",
"toilsome",
"tombless",
"tonal",
"toneless",
"tongueless",
"tonguelike",
"tonish",
"tonnish",
"tony",
"toothless",
"toothlike",
"toothsome",
"toothy",
"topfull",
"topless",
"topmost",
"torose",
"torpid",
"torquate",
"torrent",
"tortile",
"tortious",
"tortured",
"tother",
"touching",
"touchy",
"toughish",
"touring",
"tourist",
"toward",
"towered",
"townish",
"townless",
"towy",
"toxic",
"toyless",
"toylike",
"traceless",
"trackless",
"tractile",
"tractrix",
"trainless",
"tranquil",
"transcribed",
"transient",
"transposed",
"traplike",
"trappy",
"trashy",
"traveled",
"travelled",
"traverse",
"treacly",
"treasured",
"treen",
"trembling",
"trembly",
"trenchant",
"trendy",
"tressured",
"tressy",
"tribal",
"tribeless",
"trichoid",
"trickish",
"trickless",
"tricksome",
"tricksy",
"tricky",
"tricorn",
"trident",
"trifid",
"trifling",
"triform",
"trillion",
"trillionth",
"trilobed",
"trinal",
"triploid",
"trippant",
"tripping",
"tristful",
"triter",
"tritest",
"triune",
"trivalve",
"trochal",
"trochoid",
"trodden",
"trophic",
"trophied",
"tropic",
"troppo",
"trothless",
"troublous",
"truant",
"truceless",
"truer",
"truffled",
"truncate",
"trunnioned",
"trustful",
"trusting",
"trustless",
"trusty",
"truthful",
"truthless",
"tryptic",
"tsarism",
"tsarist",
"tubal",
"tubate",
"tubby",
"tubeless",
"tumbling",
"tumid",
"tuneful",
"tuneless",
"turbaned",
"turbid",
"turdine",
"turfy",
"turgent",
"turgid",
"tuskless",
"tussal",
"tussive",
"tutti",
"twaddly",
"tweedy",
"twelvefold",
"twenty",
"twiggy",
"twinkling",
"twinning",
"twofold",
"typal",
"typhous",
"typic",
"ugsome",
"ullaged",
"umber",
"umbral",
"umbrose",
"umpteen",
"umpteenth",
"unaimed",
"unaired",
"unapt",
"unarmed",
"unasked",
"unawed",
"unbacked",
"unbagged",
"unbaked",
"unbarbed",
"unbarred",
"unbathed",
"unbegged",
"unbent",
"unbid",
"unblamed",
"unbleached",
"unblenched",
"unblent",
"unblessed",
"unblocked",
"unblown",
"unboned",
"unborn",
"unborne",
"unbought",
"unbound",
"unbowed",
"unbraced",
"unbranched",
"unbreached",
"unbreathed",
"unbred",
"unbreeched",
"unbridged",
"unbroke",
"unbruised",
"unbrushed",
"unburned",
"unburnt",
"uncaged",
"uncalled",
"uncapped",
"uncashed",
"uncaught",
"uncaused",
"unchained",
"unchanged",
"uncharge",
"uncharged",
"uncharmed",
"unchaste",
"unchecked",
"uncheered",
"unchewed",
"unclad",
"unclaimed",
"unclassed",
"unclean",
"uncleaned",
"uncleansed",
"unclear",
"uncleared",
"unclimbed",
"unclipped",
"unclogged",
"unclutched",
"uncocked",
"uncoined",
"uncombed",
"uncooked",
"uncouth",
"uncropped",
"uncross",
"uncrowned",
"unculled",
"uncurbed",
"uncured",
"uncursed",
"uncurved",
"uncut",
"undamped",
"undeaf",
"undealt",
"undecked",
"undimmed",
"undipped",
"undocked",
"undone",
"undrained",
"undraped",
"undrawn",
"undreamed",
"undreamt",
"undress",
"undressed",
"undried",
"undrilled",
"undrowned",
"undrunk",
"undubbed",
"undue",
"undug",
"undulled",
"undyed",
"unfair",
"unfanned",
"unfeared",
"unfed",
"unfelled",
"unfelt",
"unfenced",
"unfiled",
"unfilled",
"unfilmed",
"unfine",
"unfired",
"unfirm",
"unfished",
"unfit",
"unflawed",
"unfledged",
"unflushed",
"unfooled",
"unforced",
"unforged",
"unformed",
"unfought",
"unfound",
"unframed",
"unfraught",
"unfree",
"unfunded",
"unfurred",
"ungalled",
"ungauged",
"ungeared",
"ungilt",
"ungirthed",
"unglad",
"unglazed",
"unglossed",
"ungloved",
"ungored",
"ungorged",
"ungowned",
"ungraced",
"ungrassed",
"ungrazed",
"ungroomed",
"unground",
"ungrown",
"ungrudged",
"ungual",
"unguessed",
"unguled",
"ungummed",
"ungyved",
"unhacked",
"unhailed",
"unhanged",
"unharmed",
"unhatched",
"unhealed",
"unheard",
"unhelped",
"unhewn",
"unhinged",
"unhired",
"unhooped",
"unhorsed",
"unhung",
"unhurt",
"unhusked",
"unique",
"unjust",
"unkempt",
"unkenned",
"unkept",
"unkind",
"unkinged",
"unkissed",
"unknelled",
"unlaid",
"unlearned",
"unlearnt",
"unleased",
"unled",
"unlet",
"unlike",
"unlimed",
"unlined",
"unlit",
"unlooked",
"unlopped",
"unlost",
"unloved",
"unmade",
"unmailed",
"unmaimed",
"unmanned",
"unmarked",
"unmarred",
"unmasked",
"unmatched",
"unmeant",
"unmeet",
"unmet",
"unmilked",
"unmilled",
"unmissed",
"unmixed",
"unmoaned",
"unmourned",
"unmoved",
"unmown",
"unnamed",
"unoiled",
"unowned",
"unpaced",
"unpaged",
"unpaid",
"unpained",
"unpaired",
"unpared",
"unpaved",
"unpeeled",
"unpent",
"unperched",
"unpicked",
"unpierced",
"unplaced",
"unplagued",
"unplanked",
"unplayed",
"unpleased",
"unpledged",
"unploughed",
"unplucked",
"unplumb",
"unplumbed",
"unplumed",
"unpoised",
"unpolled",
"unposed",
"unpraised",
"unpreached",
"unpressed",
"unpriced",
"unprimed",
"unprized",
"unpropped",
"unproved",
"unpruned",
"unpurged",
"unquelled",
"unquenched",
"unraised",
"unraked",
"unreached",
"unread",
"unreaped",
"unreined",
"unrent",
"unrhymed",
"unribbed",
"unrigged",
"unrimed",
"unripe",
"unroped",
"unrouged",
"unroused",
"unrubbed",
"unrude",
"unruled",
"unsafe",
"unsaid",
"unsailed",
"unsapped",
"unsashed",
"unsaved",
"unscaled",
"unscanned",
"unscarred",
"unscathed",
"unschooled",
"unscorched",
"unscoured",
"unscratched",
"unscreened",
"unsealed",
"unsearched",
"unseen",
"unseized",
"unsensed",
"unsent",
"unset",
"unshamed",
"unshaped",
"unshared",
"unshaved",
"unsheathed",
"unshed",
"unshipped",
"unshocked",
"unshod",
"unshoed",
"unshorn",
"unshown",
"unshrived",
"unshunned",
"unshut",
"unsight",
"unsigned",
"unsized",
"unskilled",
"unskimmed",
"unskinned",
"unslain",
"unsliced",
"unsluiced",
"unslung",
"unsmirched",
"unsmooth",
"unsmoothed",
"unsnuffed",
"unsoaped",
"unsoft",
"unsoiled",
"unsold",
"unsolved",
"unsought",
"unsound",
"unsown",
"unspared",
"unsparred",
"unspelled",
"unspent",
"unspied",
"unspilled",
"unspilt",
"unspoiled",
"unspoilt",
"unsprung",
"unspun",
"unsquared",
"unstack",
"unstacked",
"unstaid",
"unstained",
"unstamped",
"unstarched",
"unstilled",
"unstirred",
"unstitched",
"unstocked",
"unstopped",
"unstrained",
"unstreamed",
"unstressed",
"unstringed",
"unstriped",
"unstripped",
"unstrung",
"unstuck",
"unstuffed",
"unsucked",
"unsung",
"unsure",
"unswayed",
"unswept",
"unsworn",
"untailed",
"untame",
"untamed",
"untanned",
"untapped",
"untarred",
"untaught",
"unteamed",
"unthanked",
"unthawed",
"unthought",
"untied",
"untiled",
"untilled",
"untinged",
"untinned",
"untired",
"untold",
"untombed",
"untoned",
"untorn",
"untouched",
"untraced",
"untracked",
"untrained",
"untrenched",
"untressed",
"untried",
"untrimmed",
"untrod",
"untrue",
"unturfed",
"unturned",
"unurged",
"unused",
"unversed",
"unvexed",
"unviewed",
"unvoiced",
"unwaked",
"unwarmed",
"unwarned",
"unwarped",
"unwashed",
"unwatched",
"unweaned",
"unwebbed",
"unwed",
"unweened",
"unweighed",
"unwell",
"unwept",
"unwet",
"unwhipped",
"unwilled",
"unwinged",
"unwiped",
"unwired",
"unwise",
"unwished",
"unwitched",
"unwon",
"unwooed",
"unworked",
"unworn",
"unwound",
"unwrapped",
"unwrought",
"unwrung",
"upbeat",
"upbound",
"upcast",
"upgrade",
"uphill",
"upmost",
"uppish",
"upraised",
"upset",
"upstage",
"upstaged",
"upstair",
"upstairs",
"upstart",
"upstate",
"upstream",
"uptight",
"uptown",
"upturned",
"upward",
"upwind",
"urbane",
"urdy",
"urgent",
"urnfield",
"useful",
"useless",
"utile",
"utmost",
"vadose",
"vagal",
"vagrant",
"vagrom",
"vaguer",
"vaguest",
"valanced",
"valgus",
"valiant",
"valid",
"valval",
"valvar",
"valvate",
"vambraced",
"vaneless",
"vanward",
"vapid",
"varied",
"varus",
"vassal",
"vasty",
"vatic",
"vaulted",
"vaulting",
"vaunted",
"vaunting",
"vaunty",
"veilless",
"veiny",
"velar",
"velate",
"vellum",
"venal",
"vengeful",
"venose",
"venous",
"ventose",
"verbless",
"verbose",
"verdant",
"verism",
"verist",
"vespine",
"vestral",
"vibrant",
"viceless",
"viewless",
"viewy",
"villose",
"villous",
"vinous",
"viral",
"virgate",
"virile",
"visaged",
"viscid",
"viscose",
"viscous",
"vitric",
"vivid",
"vivo",
"vixen",
"voetstoots",
"vogie",
"voiceful",
"voiceless",
"voided",
"volant",
"volar",
"volumed",
"volvate",
"vorant",
"voteless",
"votive",
"vulpine",
"vying",
"wacky",
"wageless",
"waggish",
"waggly",
"wailful",
"wailing",
"waisted",
"wakeful",
"wakeless",
"wakerife",
"waking",
"walnut",
"wambly",
"wandle",
"waney",
"waning",
"wanner",
"wannest",
"wanning",
"wannish",
"wanting",
"wanton",
"warded",
"warlike",
"warming",
"warmish",
"warning",
"warring",
"wartless",
"wartlike",
"warty",
"wary",
"washy",
"waspish",
"waspy",
"wasted",
"wasteful",
"watchful",
"waveless",
"wavelike",
"waving",
"wavy",
"waxen",
"waxing",
"waxy",
"wayless",
"wayward",
"wayworn",
"weakly",
"weaponed",
"wearied",
"wearing",
"wearish",
"weary",
"weathered",
"webby",
"wedded",
"wedgy",
"weedy",
"weekday",
"weekly",
"weeny",
"weepy",
"weer",
"weest",
"weighted",
"weighty",
"welcome",
"weldless",
"westbound",
"western",
"wetter",
"wettish",
"whacking",
"whacky",
"whapping",
"whate'er",
"wheaten",
"wheezing",
"wheezy",
"wheyey",
"whilom",
"whining",
"whinny",
"whiny",
"whiplike",
"whirring",
"whiskered",
"whitish",
"whittling",
"whity",
"wholesale",
"wholesome",
"whopping",
"whoreson",
"whorish",
"wicked",
"wicker",
"wider",
"widespread",
"widest",
"widish",
"wieldy",
"wifeless",
"wifely",
"wiggly",
"wigless",
"wiglike",
"wilful",
"willful",
"willing",
"willyard",
"wily",
"wimpy",
"windburned",
"winded",
"windproof",
"windswept",
"windy",
"wingless",
"winglike",
"wintry",
"winy",
"wiretap",
"wiring",
"wiry",
"wiser",
"wisest",
"wising",
"wispy",
"wistful",
"witchy",
"withdrawn",
"withy",
"witless",
"witted",
"witting",
"witty",
"wizard",
"wizen",
"wizened",
"woaded",
"wobbling",
"woeful",
"woesome",
"wolfish",
"wonky",
"wonted",
"wooded",
"woodless",
"woodsy",
"woodwind",
"woolen",
"woollen",
"woozier",
"woozy",
"wordless",
"wordy",
"workless",
"worldly",
"worldwide",
"wormy",
"worried",
"worser",
"worshipped",
"worthless",
"worthwhile",
"worthy",
"wounded",
"woundless",
"woven",
"wrapround",
"wrathful",
"wrathless",
"wreathless",
"wreckful",
"wretched",
"wrier",
"wriest",
"wriggly",
"wrinkly",
"writhen",
"writhing",
"written",
"wrongful",
"xanthous",
"xerarch",
"xeric",
"xiphoid",
"xylic",
"xyloid",
"yarer",
"yarest",
"yawning",
"yclept",
"yearling",
"yearlong",
"yearly",
"yearning",
"yeastlike",
"yeasty",
"yester",
"yestern",
"yielding",
"yogic",
"yolky",
"yonder",
"younger",
"youthful",
"yttric",
"yuletide",
"zany",
"zealous",
"zebrine",
"zeroth",
"zestful",
"zesty",
"zigzag",
"zillion",
"zincky",
"zincoid",
"zincous",
"zincy",
"zingy",
"zinky",
"zippy",
"zonate",
"zoning"];